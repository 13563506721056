import React from "react";
import "../style/style.module.scss";
import { useStaticQuery, graphql } from "gatsby";
import s from '../style/nosotrosPage.module.scss';
import Header from '../components/Header';
import NosotrosBody from '../components/NosotrosBody';
import Footer from '../components/Footer';
import { TagManager, TagManagerFooter } from '../components/TagManager';
import MetaData from '../components/MetaData';
import PageView from '../components/PageView';
import {isMobile} from 'react-device-detect';
import { DESKTOP, LAPTOP, MOBILE } from '../config.js';


const NosotrosPage = () => {
    const productType = 'Nosotros';
    const data = useStaticQuery(graphql` {
        gcms {
              headers (where: {status: PUBLISHED, landingType: Nosotros}){
                  landingType
                  preTitle
                  title
                  subTitle{
                      html
                  }
              }
        },
        desktop: file(relativePath: { eq: "Nosotros.jpg"}) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 3840) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        laptop: file(relativePath: { eq: "Nosotros-small.jpg"}) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1400) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        mobile: file(relativePath: { eq: "Nosotros-mobile.jpg"}) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        },
    }`);
    const headers = data.gcms.headers;
    let imageDataSrc = [
        {
            ...data.mobile.childImageSharp.fluid,
            media: MOBILE,
        },
    ];
    if (!isMobile) {
        imageDataSrc = [
            {
                ...data.desktop.childImageSharp.fluid,
                media: DESKTOP,
            },
            {
                ...data.laptop.childImageSharp.fluid,
                media: LAPTOP,
            },
        ];
    }
    return(
        <div>
            <MetaData/>
            <TagManager/>
            <PageView/>
            <Header preTitle={headers[0].preTitle} title={headers[0].title} subTitle={headers[0].subTitle.html}
             className={s.headerContainer} backgrounds={imageDataSrc} productType={productType} >
            </Header>
            <NosotrosBody/>
            <Footer/>
            <TagManagerFooter/>
        </div>
    )
}

export default NosotrosPage
