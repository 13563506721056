import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import s from './NosotrosHelp.module.scss';
import check from '../images/icons/checked.svg';


const Help = () => {
    const data = useStaticQuery(graphql`{
        gcms {
            destinosCreditoes (where: {status: PUBLISHED}) {
                destino
            }
        },
		office: file(relativePath: { eq: "office-2.png"}) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        },
    }`);
    
    const destinosCreditoes = data.gcms.destinosCreditoes;

    return (
        <div className={s.container}>
            <div className={s.left}>
                <p className={s.title}>¿Qué podés hacer con un Crédito Agilis?</p>
                <div className={s.checksContainer}>
                    {destinosCreditoes.map((text, index) => {
                        return (
                            <div className={s.list} key={index}>
                                <img src={check} alt="check"/>
                                <p className={s.listText}>{text.destino}</p>
                            </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className={s.imgContainer}>
            <Img className={s.rightImg} fluid={data.office.childImageSharp.fluid} objectFit='contain' alt="imagen grupo"/>
            </div>
        </div>
    )
}

export default Help
