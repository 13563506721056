import React from "react";
import s from './NosotrosNumbers.module.scss';


const Numbers = () => {
  return (
    <div className={s.container}>
        <p className={s.title}>AGILIS en números</p>
        <div className={s.boxContainer}>
            <div className={s.numbers}>
                <p className={s.digit}>$ 550M</p>
                <p className={s.line}>Monto en créditos otorgados</p>
            </div>
            <div className={s.numbers} >
                <p className={s.digit}>+ 3.5K</p>
                <p className={s.line}>Clientes Pre-Aprobados en 2019</p>
            </div>
            <div className={s.numbers}>
                <p className={s.digit}>+ 150</p>
                <p className={s.line}>Créditos Pre-Aprobados la semana anterior</p>
            </div>
        </div>
    </div>
  )
}

export default Numbers
