import React from "react";
import Img from 'gatsby-image';
import s from './Mision.module.scss';
import greenCheck from '../images/icons/checked.svg';
import { useStaticQuery, graphql } from "gatsby";
import {isMobile} from 'react-device-detect';

const Mision = () => {
    const data = useStaticQuery(graphql`{
        gcms {
            misions (where: {status: PUBLISHED}) {
                text {
                    html
                }
            }
        },
        learning: file(relativePath: { eq: "learning.png"}) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }`);

    const misions = data.gcms.misions;

    return (
        <div className={s.misionContainer}>
            <Img fluid={data.learning.childImageSharp.fluid} className={s.misionImg} alt='foto miembros oficina'/>
            <div className={s.textContainer}>
                <h1 className={s.title}>Nuestra misión</h1>
                <p className={s.subTitle}>Crear la mejor plataforma digital de Créditos con Garantía de Argentina a través de:</p>
                {misions.map((mision, index) =>
                    <div className={s.checksContainer} key={index}>
                        <img src={greenCheck} className={greenCheck} style={isMobile ? {marginRight: '0px', maxWidth: '24px'} : {marginRight: '20px', maxWidth: '24px'}} alt='check verde'/>
                        <div className={s.text} dangerouslySetInnerHTML={{__html: mision.text.html}}></div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Mision
