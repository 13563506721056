import React from "react";
import {isMobile} from 'react-device-detect';
import { useStaticQuery, graphql } from "gatsby";
import Img from 'gatsby-image';

import s from './NosotrosBody.module.scss';
import Mision from './Mision';
import NosotrosHelp from './NosotrosHelp.js';
import NosotrosMedia from './NosotrosMedia';
import NosotrosJobs from './NosotrosJobs.js';
import NosotrosNumbers from './NosotrosNumbers.js';
import mobile1 from '../images/mobile-people.svg';
import mobile2 from '../images/mobile-hands.svg';
import mobile3 from '../images/mobile-3.svg';
import mobile4 from '../images/mobile-4.svg';
import desk1 from '../images/desk-1.svg';
import desk2 from '../images/desk-2.svg';
import desk3 from '../images/desk-3.svg';
import desk4 from '../images/desk-4.svg';

const NosotrosBody = () => {
    const data = useStaticQuery(graphql`{
        gcms {
            generalNews: newses(where: {status: PUBLISHED}){
                imagenLogoGris{
                    handle
                    width
                    height
                }
                imagenLogoColor{
                    handle
                    width
                    height
                }
                logoMobile{
                    handle
                    width
                    height
                }
                logoColorChico{
                    handle
                    width
                    height
                }
                title
                description {
                    text
                }
                alias
                link
            }
        },
        jobs: file(relativePath: { eq: "jobs.jpg"}) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 750) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
        },

    }`);
    const newses = data.gcms;

    return (
        <div>
            <div className={s.bodyContainer}>
                <Mision />
                <div className={s.bodyImg}>
                    <div className={s.numbers}>
                        <NosotrosNumbers/>
                        <div className={s.numbersImg}>
                            {<img className={s.lines} src={isMobile ? mobile1 : desk1} alt={"linea divisoria"}/>}
                        </div>
                    </div>
                    <div>
                        <NosotrosHelp />
                        <div className={s.helpImg}>
                            {<img className={s.lines} src={isMobile ? mobile2 : desk2} alt={"linea divisoria"}/>}
                        </div>
                    </div>
                    <NosotrosMedia newses={newses} />
                    <img className={s.line3} src={isMobile ? mobile3 : desk3} alt="linea"/>
                    {isMobile && (
                        <div id='onlymobilejobscontainer' style={{margin: '0 auto', width: '86%'}}>
                            <Img className={s.imgJobs} fluid={data.jobs.childImageSharp.fluid} alt={"Imagen oficina"} />
                        </div>
                    )}
                    <div id="empty"></div>
                    <div className={s.jobs}>
                        <NosotrosJobs/>
                        <div className={s.divLine4}>
                            <img className={s.line4} src={isMobile ? mobile4 : desk4} alt="linea"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NosotrosBody
