import React from "react";
import Img from "gatsby-image";
import {isMobile} from 'react-device-detect';
import { useStaticQuery, graphql } from "gatsby";
import s from './NosotrosJobs.module.scss';


const Jobs = () => {
    const data = useStaticQuery(graphql`{
        jobsImg: file(relativePath: { eq: "jobs-3.png"}) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }`);
     
    return (
        <div className={s.container} id="usJobsUID">
            <div className={s.textBox}>
                <p className={s.title}>¿Te interesaría ser parte de Agilis?</p>
                <p className={s.line}>Podés ver nuestras búsquedas disponibles desde acá:</p>
                <p className={s.linkP}><a className={s.link} href={"https://www.linkedin.com/company/agilis-prestamos/"} target='__blank'>Agilis Jobs</a></p>
            </div>
            {!isMobile && ( 
                <div id="jobs3containerDeskOnly">
                    <Img className={s.jobsImg} fluid={data.jobsImg.childImageSharp.fluid} alt={"Imagen oficina"}/>
                </div>
            )}
        </div>
    )
}

export default Jobs
