import React from "react";
import s from './NosotrosMedia.module.scss';
import CenterMode from'./CenterMode';
import bracket from '../images/icons/corchete.svg';
import GraphImg from "graphcms-image";


class NosotrosMedia extends React.Component{

    state = {
        colorLogo: false,
        current: this.props.newses.generalNews[0].alias
    }

    handleClick = (param) => {
        this.setState({
            current: param
        });
    }

    render(){
        const { newses } = this.props;
        const { current } = this.state;
        return (
            <div className={s.mediaContainer}>
                <h1 className={s.title}>Lo que dicen de Agilis</h1>
                <div className={s.mediaMobile}>
                   <CenterMode medios={true}>
                        {newses.generalNews.map((news, index) => {
                        return(
                            <div className={s.newsContainer} key={index}>
                                <div className={s.textContainer}>
                                    <p className={s.newTitle}>{news.title}</p>
                                    <p className={s.newDescription}>{news.description.text}</p>
                                    <a className={s.links} href={news.link} target='__blank'>Leer más</a>
                                </div>
                                <GraphImg
                                 image={news.logoMobile}
                                 maxWidth={231}
                                 withWebp={true}
                                 name={news.alias}
                                 className={s.logoImg}
                                 alt={"noticias"}
                                 key={index}
                                />
                            </div>
                        )
                        })}
                    </CenterMode>
                </div>
                <div className={s.mediaDesktop}>
                    {newses.generalNews.map((news, index) => {
                        if(news.alias === current){
                            return(
                                <div className={s.newsContainer} key={index}>
                                    <div style={{width: '271px'}} className={s.newImg} >
                                        <GraphImg
                                         image={news.imagenLogoColor}
                                         maxWidth={321}
                                         withWebp={true}
                                         alt={"noticias"}/>
                                    </div>
                                    <img src={bracket} className={s.bracket} alt='llave'/>
                                    <div className={s.textContainer}>
                                        <p className={s.newTitle}>{news.title}</p>
                                        <p className={s.newDescription}>{news.description.text}</p>
                                        <a className={s.links} href={news.link} target='__blank'>Leer más</a>
                                    </div>
                                </div>
                            )
                        }
                        return null
                    })}
                    <div className={s.logosContainer}>
                        {newses.generalNews.map((logo, index) => {
                            return (
                                <div onClick={() => this.handleClick(logo.alias)}
                                     name={logo.alias}
                                     style={{width: '271px'}}
                                     className={s.logoImg}
                                     key={index}
                                     onKeyDown={() => this.handleClick(logo.alias)} 
                                     role="button" 
                                     tabIndex="0"
                                >
                                    <GraphImg 
                                     image={(logo.alias === current) ? logo.logoColorChico : logo.imagenLogoGris }
                                     name={logo.alias}
                                     maxWidth={271}
                                     withWebp={true}
                                     alt={"noticias"}
                                    />
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default NosotrosMedia

